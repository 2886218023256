import React from "react"
import Layout from "../components/layout"
import { Heading,Text, Link, Center, Image } from "@chakra-ui/react"
import pflanze from "../media/pflanze.svg"

export default function Home() {
  return (

  <Layout title="Yoga am Teppich" slug="courses">

      <Heading
        as="h3"
        size="sm"
        mt={12}
        mb={3}
      >
        Um welchen Yogastil geht es?
      </Heading>
      <Text> Hatha Yoga bei frischer Luft und duftenden Kiefern. Für alle Level geeignet.
      </Text>
      <Heading
        as="h3"
        size="sm"
        mt={12}
        mb={3}
      >
        Was wird benötigt?
      </Heading>
      <Text>
      Es wird lediglich eine rutschfeste Unterlage und ggfs. eine Decke benötigt.
      Zusätzlich empfehle ich je nach Witterung eine warme Jacke, dicke Socken oder
      Sonnenschutz einzupacken. Wer anschließend im Müggelsee baden gehen möchte,
      benötigt zusätzlich noch ein Handtuch und Badesachen.
      </Text>
      <Heading
        as="h3"
        size="sm"
        mt={12}
        mb={3}
      >
      Wo genau treffen wir uns?
      </Heading>
      <Text mb={6}> Wir treffen uns 10 min vor Beginn des Kurses direkt am Badestrand
      Teppich (Müggelschlößchenweg 1)
      </Text>
<iframe title="openstreetmap" width="100%" height={300} src="https://www.openstreetmap.org/export/embed.html?bbox=13.612393140792848%2C52.44049891763855%2C13.63031029701233%2C52.44654159019394&amp;layer=mapnik&amp;marker=52.443520357520775%2C13.621351718902588"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=52.44352&amp;mlon=13.62135#map=17/52.44352/13.62135&amp;layers=N">View Larger Map</a></small>
      <Heading
        as="h3"
        size="sm"
        mt={12}
        mb={3}
      >
      Was kostet das?
      </Heading>
      <Text> Die Yogakurse am See sind kostenfrei.
      </Text>

      <Heading
        as="h3"
        size="sm"
        mt={12}
        mb={3}
      >
        Was passiert bei Regen oder Unwetter?
      </Heading>
      <Text> Bei Regen/Unwetter oder starker Hitze, fällt der Yogakurs aus. Sobald
      sich der Kurs Status von "geplant" auf "bestätigt" verändert (ca. 24 h vorher),
      findet der geplante Yogakurs am Wasser definitiv statt.
      </Text>
      <br />
      <Text> Noch Fragen? Dann freue ich mich über eine Nachricht <Link to="/contact/">(Kontakt).</Link>
      </Text>
      <Center mt={10}>
        <Image src={pflanze} alt="Illustration von einer Pflanze" />
        </Center>
    </Layout>
  )
}
